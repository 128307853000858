import Vue from 'vue';
import App from '@/App.vue';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import VueAgile from 'vue-agile';
import store from '@/Store';
import i18n from '@/i18n';
import VueCookies from 'vue-cookies';
import Clipboard from 'v-clipboard';
import axios from "axios";
import VueRouter from 'vue-router';
import routes from '@/router';
import VueMeta from 'vue-meta';
import VueScrollTo from 'vue-scrollto';

import '@/css/app.css';

axios.interceptors.response.use(
    response => response,
    error => {
      const status = error.response;
      if (status === 401) {
        // do stuff
      }
    }
);
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAgile);
Vue.use(VueCookies, {expire: '7d'});
Vue.use(Clipboard);

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease-out",
    offset: -90,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});


// Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  render: h => h(App),
  router: routes,
}).$mount('#app');

<template>
    <div class="content" id="goods">
        <div class="goods">
            <div class="goods-title">{{ $t('text.needs') }}:</div>
            <a href="https://docs.google.com/spreadsheets/u/0/d/1cacSvOJVyg6m7SdlgHxhUzaxj6nGN64G07GeKlDQcMg/htmlview?fbclid=IwAR12DYPgJ0X3hqnNSmhmewYZxEydQNqX8KO5UMCX7Z-Xs6drJChCImtx7W8"
               class="goods-title2">{{ $t('text.needs.list') }} <i class="fa-solid fa-arrow-up-right-from-square"></i></a>
        </div>
        <div class="goods-block">
            <div class="goods-row">
                <div class="goods-item">
                    <img src="/images/aid-kit.png">
                    <div class="gi-title">{{ $t('text.fak') }} IFAK</div>
                    <div class="gi-text">{{ $t('text.needed.quantity') }}: <span>30 {{ $t('text.pcs') }}</span></div>
                    <div class="gi-text">{{ $t('text.price.per.unit') }}: <span>~100$</span></div>
                </div>
                <div class="goods-item">
                    <img src="/images/lidar.png">
                    <div class="gi-title">{{ $t('text.ldm') }}</div>
                    <div class="gi-text">{{ $t('text.needed.quantity') }}: <span>2 {{ $t('text.pcs') }}</span></div>
                    <div class="gi-text">{{ $t('text.price.per.unit') }}: <span>~2100$</span></div>
                </div>
            </div>
            <div class="goods-row">
                <div class="goods-item">
                    <img src="/images/earphones.png">
                    <div class="gi-title">{{ $t('text.earphones') }} MSA Sordin Supreme Pro</div>
                    <div class="gi-text">{{ $t('text.needed.quantity') }}: <span>15 {{ $t('text.pcs') }}</span></div>
                    <div class="gi-text">{{ $t('text.price.per.unit') }}: <span>~350$</span></div>
                </div>
                <div class="goods-item">
                    <img src="/images/bus.png">
                    <div class="gi-title">{{ $t('text.bus') }}</div>
                    <div class="gi-text">{{ $t('text.needed.quantity') }}: <span>1 {{ $t('text.pcs') }}</span></div>
                    <div class="gi-text">{{ $t('text.price.per.unit') }}: <span>~4200$</span></div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "GoodsComponent"
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies';

Vue.use(VueI18n);
Vue.use(VueCookies, {expire: '7d'});

let paramLoc = '';
let pathArr = window.location.pathname.split('/');
if (pathArr.length > 1) {
    paramLoc = pathArr[1];
}

if (paramLoc === 'en') {
    Vue.$cookies.set('locale', paramLoc);
}

let loc = Vue.$cookies.get('locale') ? Vue.$cookies.get('locale') : 'ua';


function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export default new VueI18n({
    locale: loc,
    fallbackLocale: loc,
    messages: loadLocaleMessages()
});

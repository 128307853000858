<template>
    <div class="header" :class="scrolled ? 'scroll' : ''">
        <div class="header-block">
            <div class="header-left">
                <div class="logo">
                    <a href="/"><img src="/images/logo-will.png" alt=""></a>
                </div>
                <div class="langs">
                    <div class="language"
                         :class="this.$store.getters['Locale/getLocale'] === 'en' ? 'selected-language' : ''"
                         @click="changeLocale('en')">EN
                    </div>
                    <div class="language"
                         :class="this.$store.getters['Locale/getLocale'] === 'ua' ? 'selected-language' : ''"
                         @click="changeLocale('ua')">UA
                    </div>
                </div>
            </div>
            <div class="header-right">
                <div class="langs2">
                    <div class="language"
                         :class="this.$store.getters['Locale/getLocale'] === 'en' ? 'selected-language' : ''"
                         @click="changeLocale('en')">EN
                    </div>
                    <div class="language"
                         :class="this.$store.getters['Locale/getLocale'] === 'ua' ? 'selected-language' : ''"
                         @click="changeLocale('ua')">UA
                    </div>
                </div>
                <MenuComponent/>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuComponent from "@/components/MenuComponent";

    export default {
        name: "HeaderComponent",
        components: {
            MenuComponent
        },
        data() {
            return {
                scrolled: false,
            }
        },
        methods: {
            handleScroll() {
                this.scrolled = window.scrollY > 0;
            },

            changeLocale(locale) {
                this.$i18n.locale = locale;
                this.$cookies.set('locale', locale);
                this.$store.dispatch('Locale/changeLocale', locale);

                if (locale === 'en') {
                    this.$router.push('/' + locale + this.$route.path).catch(() => {
                    });
                } else {
                    let pathnameArr = this.$route.path.split('/')
                    if (pathnameArr.length > 2) {
                        this.$router.push('/' + pathnameArr[2]).catch(() => {
                        });
                    } else {
                        this.$router.push('/' + this.$route.path).catch(() => {
                        });
                    }
                }
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        mounted() {
            if (this.$cookies.get('scroll')) {
                this.$scrollTo('#' + this.$cookies.get('scroll'), 500);
                this.$cookies.remove('scroll');
            }
            this.$store.dispatch('Locale/changeLocale', this.$i18n.locale);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style scoped>
    .language {
        cursor: pointer;
    }

    .selected-language {
        color: black;
    }
</style>

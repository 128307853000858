<template>
    <div class="content footer">
        <div>Copyright 2022 will.in.ua. All rights reserved</div>
    </div>

</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style scoped>

</style>

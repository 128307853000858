<template>
   <AppLayout>
      <div class="content">
         <div class="q-title">{{ $t('text.payments') }}</div>

         <!--            <div v-if="locale === 'ua'" class="one-third">-->
         <!--                <div class="one-third-one">-->
         <!--                    <div>{{ $t('text.at') }} «{{ $t('text.ukrsibbank') }}»</div>-->
         <!--                    <div>UAH</div>-->
         <!--                </div>-->
         <!--                <div class="two-third">-->
         <!--                    <div class="flex flex-wrap align-items-start">-->
         <!--                        <div class="two-third-one">№ картки:</div>-->
         <!--                        <div class="two-third-two">-->
         <!--                            <span class="iban">5582&nbsp;5923&nbsp;2081&nbsp;0766</span>-->
         <!--                            <div>-->
         <!--                                <i class="fa-regular fa-copy" @click="copyIban"></i>-->
         <!--                                <span class="copyed">{{ $t('text.copied') }}</span>-->
         <!--                            </div>-->
         <!--                        </div>-->
         <!--                    </div>-->
         <!--                </div>-->

         <!--            </div>-->
         <!--            <hr v-if="locale === 'ua'">-->

         <div class="one-third">
            <div class="one-third-one">
               <div>{{ $t('text.at_m') }} «{{ $t('text.monobank') }}»</div>
               <div>UAH</div>
            </div>
            <div class="two-third">
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.link') }}:</div>
                  <div class="two-third-two">
                     <a v-if="link" :href='link' class="iban">{{ link }}</a>
                     <div v-if="link">
                        <i class="fa-regular fa-copy" @click="copyIban"></i>
                        <span class="copyed">{{ $t('text.copied') }}</span>
                     </div>
                  </div>
               </div>
            </div>

         </div>
         <hr>

         <div class="one-third">
            <div class="one-third-one">
               <div>PayPal</div>
            </div>
            <div class="two-third">
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">itworksinua@gmail.com</div>
               </div>
            </div>
         </div>
         <hr>

         <div class="one-third">
            <div class="one-third-one">
               <div>{{ $t('text.at') }} «{{ $t('text.ukrsibbank') }}»</div>
               <div>UAH</div>
            </div>
            <div class="two-third">
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">IBAN:</div>
                  <div class="two-third-two">
                     <span class="iban">UA563510050000026009879150501</span>
                     <div>
                        <i class="fa-regular fa-copy" @click="copyIban"></i>
                        <span class="copyed">{{ $t('text.copied') }}</span>
                     </div>
                  </div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.beneficiary') }}:</div>
                  <div class="two-third-two">{{ $t('text.charity.foundation') }}</div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.code.edrpou') }}:</div>
                  <div class="two-third-two">44698549</div>
               </div>
            </div>

         </div>
         <hr>

         <div class="one-third">
            <div class="one-third-one">
               <div>{{ $t('text.at') }} «{{ $t('text.ukrsibbank') }}»</div>
               <div>EUR</div>
            </div>
            <div class="two-third">
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">IBAN:</div>
                  <div class="two-third-two">
                     <span class="iban">UA133510050000026006879150504</span>
                     <div>
                        <i class="fa-regular fa-copy" @click="copyIban"></i>
                        <span class="copyed">{{ $t('text.copied') }}</span>
                     </div>
                  </div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">SWIFT:</div>
                  <div class="two-third-two">KHABUA2K</div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.beneficiary') }}:</div>
                  <div class="two-third-two">{{ $t('text.charity.foundation') }}</div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.code.edrpou') }}:</div>
                  <div class="two-third-two">44698549</div>
               </div>

            </div>

         </div>
         <hr>

         <div class="one-third">
            <div class="one-third-one">
               <div>{{ $t('text.at') }} «{{ $t('text.ukrsibbank') }}»</div>
               <div>USD</div>
            </div>
            <div class="two-third">
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">IBAN:</div>
                  <div class="two-third-two">
                     <span class="iban">UA923510050000026007879150503</span>
                     <div>
                        <i class="fa-regular fa-copy" @click="copyIban"></i>
                        <span class="copyed">{{ $t('text.copied') }}</span>
                     </div>
                  </div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">SWIFT:</div>
                  <div class="two-third-two">KHABUA2K</div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.beneficiary') }}:</div>
                  <div class="two-third-two">{{ $t('text.charity.foundation') }}</div>
               </div>
               <div class="flex flex-wrap align-items-start">
                  <div class="two-third-one">{{ $t('text.code.edrpou') }}:</div>
                  <div class="two-third-two">44698549</div>
               </div>
            </div>

         </div>
         <hr>


      </div>
   </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout";

export default {
   name: "RequisitesScreen",
   data() {
      return {
         locale: this.$i18n.locale,
         link: null
      }
   },
   components: {
      AppLayout
   },
   methods: {
      copyIban(event) {
         // Get the text field
         const copyText = event.target.parentElement.parentElement.getElementsByClassName('iban')[0].innerHTML.replaceAll('&nbsp;', '');

         // Copy the text inside the text field
         //navigator.clipboard.writeText(copyText);
         this.$clipboard(copyText);

         event.target.classList.toggle('disabled');

         const span = event.target.parentElement.getElementsByClassName('copyed')[0];
         span.classList.toggle('visible');

         setTimeout(() => {
            event.target.classList.toggle('disabled');
            span.classList.toggle('visible');
         }, 5000);
      },

      async readFile() {
         try {
            const response = await fetch('/banka.txt');
            const text = await response.text();
            this.link = text.trim();
         } catch (error) {
            console.error('Error reading file:', error);
         }
      }
   },
   watch: {
      '$i18n.locale': function (newVal) {
         this.locale = newVal;
      }
   },
   mounted() {
      setTimeout(() => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      }, 1);
      this.readFile();
   }
}
</script>

<style>

</style>

<template>
    <AppLayout>
        <HeadComponent/>
        <GoodsComponent/>
        <TeamComponent/>
        <QuestionsComponent v-if="locale === 'ua'"/>

    </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/AppLayout";
import HeadComponent from "@/components/HeadComponent";
import GoodsComponent from "@/components/GoodsComponent";
import TeamComponent from "@/components/TeamComponent";
import QuestionsComponent from "@/components/QuestionsComponent";

export default {
    name: "HomeScreen",
    components: {
        AppLayout,
        HeadComponent,
        GoodsComponent,
        TeamComponent,
        QuestionsComponent
    },
    data() {
        return {
            locale: this.$i18n.locale,
        }
    },
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    methods: {},
    mounted() {}
}
</script>

<style scoped>

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wide",attrs:{"id":"team"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"team-title"},[_vm._v(_vm._s(_vm.$t('menu.team')))]),_c('div',{staticClass:"team-block"},[_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/12.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('dmytro.davydov')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.director')))]),_vm._m(0)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/11.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('levandovskiyw')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(1)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/13.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('vlada.yasko')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(2)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/14.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('alisa.batiuk')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(3)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/15.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('kupedonio')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(4)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/16.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('videiko')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(5)]),_c('div',{staticClass:"team-item"},[_c('img',{attrs:{"src":"/images/17.png"}}),_c('div',{staticClass:"ti-name"},[_vm._v(_vm._s(_vm.$t('daryshyn')))]),_c('div',{staticClass:"ti-stuff"},[_vm._v(_vm._s(_vm.$t('text.volunteer')))]),_vm._m(6)])]),_c('SliderComponent')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.facebook.com/davydovdmy"}},[_c('img',{attrs:{"src":"/images/icons/ico-fb.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.facebook.com/levandovskiyw/"}},[_c('img',{attrs:{"src":"/images/icons/ico-fb.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.facebook.com/vlada.yasko"}},[_c('img',{attrs:{"src":"/images/icons/ico-fb.png"}})]),_c('a',{attrs:{"href":"https://www.instagram.com/v__yasko/"}},[_c('img',{attrs:{"src":"/images/icons/ico -instagram.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.facebook.com/AlisaBatiuk"}},[_c('img',{attrs:{"src":"/images/icons/ico-fb.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.instagram.com/kupedonio/"}},[_c('img',{attrs:{"src":"/images/icons/ico -instagram.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100007729994924"}},[_c('img',{attrs:{"src":"/images/icons/ico-fb.png"}})]),_c('a',{attrs:{"href":"https://www.instagram.com/okiediv/"}},[_c('img',{attrs:{"src":"/images/icons/ico -instagram.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ti-social"},[_c('a',{attrs:{"href":"https://www.instagram.com/daryshyn/"}},[_c('img',{attrs:{"src":"/images/icons/ico -instagram.png"}})])])
}]

export { render, staticRenderFns }
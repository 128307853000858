<template>
    <div>
        <div class="main-menu" v-if="this.$route.name === 'HomeScreen'">
            <a href="#" v-scroll-to="'#main'">{{ $t('menu.main') }}</a>
            <a href="#" v-scroll-to="'#goods'">{{ $t('menu.equipment') }}</a>
            <a href="#" v-scroll-to="'#team'">{{ $t('menu.team') }}</a>
            <a href="#" v-scroll-to="'#reports'">{{ $t('menu.photo') }}</a>
            <a v-if="locale === 'ua'" href="#" v-scroll-to="'#questions'">{{ $t('menu.questions') }}</a>
            <a href="#" v-scroll-to="'#contacts'">{{ $t('menu.contacts') }}</a>
        </div>
        <div class="main-menu" v-else>
            <a class="pointer" @click="routerScroll('main')">{{ $t('menu.main') }}</a>
            <a class="pointer" @click="routerScroll('goods')">{{ $t('menu.equipment') }}</a>
            <a class="pointer" @click="routerScroll('team')">{{ $t('menu.team') }}</a>
            <a class="pointer" @click="routerScroll('reports')">{{ $t('menu.photo') }}</a>
            <a v-if="locale === 'ua'" class="pointer" @click="routerScroll('questions')">{{ $t('menu.questions') }}</a>
            <a class="pointer" @click="routerScroll('contacts')">{{ $t('menu.contacts') }}</a>
        </div>
        <div class="burger-menu">
            <input type="checkbox" id="myInput" :checked="this.$store.getters['Flags/getMenuFlag']" @click="changeFlag">
            <label for="myInput">
                <span class="bar top"></span>
                <span class="bar middle"></span>
                <span class="bar bottom"></span>
            </label>
            <div class="menu_overlay">
                <div class="main-menu-block" v-if="this.$route.name === 'HomeScreen'">
                    <a href="#" v-scroll-to="'#main'" @click="changeFlag" class="main-menu-item">
                        {{ $t('menu.main') }}
                    </a>
                    <a href="#" v-scroll-to="'#goods'" @click="changeFlag" class="main-menu-item">
                        {{ $t('menu.equipment') }}
                    </a>
                    <a href="#" v-scroll-to="'#team'" @click="changeFlag" class="main-menu-item">
                        {{ $t('menu.team') }}</a>
                    <a href="#" v-scroll-to="'#reports'" @click="changeFlag" class="main-menu-item">
                        {{ $t('menu.photo') }}</a>
                    <a v-if="locale === 'ua'" href="#" v-scroll-to="'#questions'" @click="changeFlag"
                       class="main-menu-item">{{
                        $t('menu.questions') }}</a>
                    <a href="#" v-scroll-to="'#contacts'" @click="changeFlag" class="main-menu-item">
                        {{ $t('menu.contacts') }}</a>
                    <hr>
                    <div class="burger-text">
                        {{ $t('text.contribute')}}:
                    </div>
                    <div class="burger-btns">
                        <div @click="help(false)" class="btn101">
                            {{ $t('text.donate') }}
                        </div>
                    </div>
                    <div v-if="locale === 'ua'" @click="help(true)" class="head-foreign">
                        <span>How can I help if I'm foreign?</span> ↗
                    </div>
                </div>
                <div class="main-menu-block" v-else>
                    <a @click="changeFlagScroll('main')" class="main-menu-item pointer">
                        {{ $t('menu.main') }}</a>
                    <a @click="changeFlagScroll('goods')" class="main-menu-item pointer">
                        {{ $t('menu.equipment') }}</a>
                    <a @click="changeFlagScroll('team')" class="main-menu-item pointer">
                        {{ $t('menu.team') }}</a>
                    <a @click="changeFlagScroll('reports')" class="main-menu-item pointer">
                        {{ $t('menu.photo') }}</a>
                    <a v-if="locale === 'ua'" @click="changeFlagScroll('questions')"
                       class="main-menu-item pointer">
                        {{ $t('menu.questions') }}</a>
                    <a @click="changeFlagScroll('contacts')" class="main-menu-item pointer">
                        {{ $t('menu.contacts') }}</a>
                    <hr>
                    <div class="burger-text">{{ $t('text.contribute')}}:</div>
                    <div class="burger-btns">
                        <div @click="help(false)" class="btn101">{{ $t('text.donate') }}</div>
                    </div>
                    <div v-if="locale === 'ua'" @click="help(true)" class="head-foreign">
                        <span>How can I help if I'm foreign?</span> ↗
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    // import {loadLocaleMessages} from "../i18n";

    export default {
        name: "MenuComponent",
        components: {},
        data() {
            return {
                scroll: true,
                checked: false,
                locale: this.$i18n.locale
            }
        },
        methods: {
            routerScroll(name) {
                this.$cookies.set('scroll', name);
                let path = this.$route.params.locale ? this.$route.params.locale : '';
                this.$router.push("/" + path).catch(() => {
                });
            },

            changeFlagScroll(name) {
                this.changeFlag();
                this.routerScroll(name)
            },

            help(setLocale) {
                if (setLocale) {
                    this.setLocale('en');
                }

                this.changeFlag();
                this.$router.push(this.$route.params.locale ? '/' + this.$route.params.locale + '/requisites' : '/requisites').catch(() => {
                });
            },

            changeFlag() {
                let flag = this.$store.getters['Flags/getMenuFlag'];
                this.$store.dispatch('Flags/changeMenuFlag', !flag);
            },

            setLocale(locale) {
                this.$i18n.locale = locale;
                this.$cookies.set('locale', locale);
                this.$store.dispatch('Locale/changeLocale', locale);
            }
        },
        watch: {
            '$i18n.locale': function (newVal) {
                this.locale = newVal;
            }
        }
    }
</script>

<style>

</style>

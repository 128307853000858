const state = () => ( {
    locale: 'ua'
});

const getters = {
    getLocale(state) {
        return state.locale;
    }
};

const mutations = {
    changeLocale(state, n) {
        state.locale = n;
    }
};

const actions = {
    changeLocale({commit}, n) {
        commit('changeLocale', n);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import VueRouter from 'vue-router';

Vue.use(VueRouter);
import Vue from "vue";

import HomeScreen from "@/Pages/Screens/HomeScreen";
import RequisitesScreen from "@/Pages/Screens/RequisitesScreen";


let router = new VueRouter({
    mode: 'history',

    routes: [
        {
            path: '/:locale(en)?',
            name: 'HomeScreen',
            component: HomeScreen,
        },
        {
            path: '/:locale(en)?/requisites',
            name: 'RequisitesScreen',
            component: RequisitesScreen,
        },
        {
            path: '*',
            redirect() {
                return '/';
            }
        }
    ]
});

export default router;

<template>
    <div class="content" id="questions">
        <div class="q-title">Запитання</div>

        <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="info">
                        <div>Що це за організація?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>WILL — команда волонтерів і однієї із захисниць України, об’єднаних дружбою,
                            довірою та спільною ідеєю: ми робимо усе, щоб наблизити перемогу України у війні з росією,
                            якнайшвидше звільнити окуповані/блоковані міста та врятувати життя людей.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="info">
                        <div>Хто стоїть за WILL?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Список людей, які сформували проєкт, можна подивитись у розділі «Команда». Там же є
                            посилання на наші профілі у соціальних мережах.
                        </b-card-text>
                        <b-card-text>Кожен з нас має певну експертизу та спеціалізацію. Хтось добре розбирається у
                            броні,
                            хтось — в електроніці, хтось — навчає військових працювати з гаджетами. У нас
                            горизонтальна структура.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="info">
                        <div>У вас є фонд?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Так! Спершу ми працювали просто як ініціативна група, але щоб працювати ефективніше
                            ми зареєстрували благодійний фонд — Благодійна Організація ”Благодійний фонд “ВІЛ ІН ЮА”.
                        </b-card-text>
                        <b-card-text>Якщо ви хочете зробити великий фінансових внесок, ми просимо не перераховувати
                            кошти нам, а одразу оплатити конкретний інвойс на спорядження для захисників і захисниць.
                            Зв’язатись з координатором можна тут.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-4 variant="info">
                        <div>У чому відмінність між вами та великими фондами?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Ми працюємо як фізичні особи, тому що є запити, які можуть швидко реалізувати лише
                            фізичні особи. Приміром, у перші дні війни неможливо було швидко придбати автівку для армії
                            через фонд. А ось купити машину на фізичну особу і на законних підставах передати у
                            користування військовій частині — без проблем.
                        </b-card-text>
                        <b-card-text>На щастя, більшість таких процедур доволі швидко оптимізуються на рівні держави, та
                            завжди є запити, для яких поки не існує швидкої процедури. Ми тут, щоб це вирішити.
                        </b-card-text>
                        <b-card-text>Крім того, великі фонди зазвичай працюють з величезними партіями товарів. Ми
                            обробляємо точкові запити від конкретних підрозділів, які воюють безпосередньо на лінії
                            фронту. Це швидше і дозволяє не писати великому фонду з питанням «де взяти один бронік і дві
                            каски?» :)
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-5 variant="info">
                        <div>Де побачити звітність?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>В <a
                            href="https://www.instagram.com/will.in.ua/?utm_medium=copy_link">Інстаграмі</a> ми
                            публікуємо фотозвіти та оголошуємо нові збори, а детальний звіт по
                            надходженням та витратам можна побачити за <a
                                href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQAu6FrGhEPwgHkmL3HDPAWsw83ZrtEWKLElTtCIGsDy0ICQ4lvyrcouzL4uGwOTybY5Fpe50HxRwK_/pubhtml">посиланням</a>.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-6 variant="info">
                        <div>Як дізнатись, на який саме підрозділ виділено техніку/спорядження?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>На жаль, ніяк. У більшості випадків ми не можемо афішувати, який підрозділ отримав
                            допомогу, тому що це інформація, яку ворогу знати не варто.
                        </b-card-text>
                        <b-card-text>Єдине, що ми можемо розказати — наша увага зосереджена на допомозі бійцям 93
                            бригади із закупівлею засобів розвідки та спостереження.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-7 variant="info">
                        <div>Чому я маю вам довіряти?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Ви і не маєте. Ми радимо кожному уважно обирати волонтерів, яким ви переказуєте
                            кошти. Ми зробили цей сайт перш за все для комунікації з компаніями і людьми, яких знали у
                            цивільному житті, та їхніх друзів. Це наш спосіб розповісти їм, чим ми займаємось, і
                            попросити про допомогу.
                        </b-card-text>
                        <b-card-text>Якщо ви не знаєте нікого з нас і не довіряєте нам — ви завжди можете переказати
                            кошти знайомим волонтерам або великим фондам, наприклад, Повернись живим, Фонду Сергія
                            Притули тощо. Ми з ними не конкуренти, ми усі працюємо над спільною масштабною задачею, тож
                            будемо раді, якщо ви їх підтримаєте.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-8 variant="info">
                        <div>Як отримати допомогу від вас?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Наразі ми точково працюємо з кількома підрозділами, людей з яких знаємо. Ви можете
                            написати нам на пошту з поясненням, що саме вам потрібно і чому саме для вашого підрозділу
                            ми повинні зробити виняток. Ми розглянемо запит, проте не можемо обіцяти, що відповімо всім
                            і одразу.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-9 variant="info">
                        <div>Чому WILL?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Бо ми наближаємо перемогу разом.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                    <b-button block v-b-toggle.accordion-10 variant="info">
                        <div>Маєте інші питання?</div>
                        <i class="fa-solid fa-chevron-up when-opened"></i>
                        <i class="fa-solid fa-chevron-down when-closed"></i>
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>Напишіть нам на пошту: dopomoga.will@gmail.com.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

        </div>
    </div>

</template>

<script>
export default {
    name: "QuestionsComponent",
    data() {
        return {}
    }
}
</script>

<style>

</style>

<template>
    <div class="content" id="main">
        <div class="head-title">{{ $t('text.strengthening') }} 🇺🇦</div>
        <div class="head-text">
            <p>{{ $t('text.helping_fighters') }}</p>
            <p>{{ $t('text.team_link') }}</p>
        </div>
        <div class="head-btns">
            <router-link :to="this.$route.params.locale ? '/'+this.$route.params.locale+'/requisites' :'/requisites'"
                         class="btn1">{{ $t('text.donate') }}
            </router-link>
        </div>
        <a v-if="locale === 'ua'" @click="setLocale('en')"
           class="head-foreign pointer">How can I help if I'm foreign? ↗</a>
    </div>
</template>

<script>
    export default {
        name: "HeadComponent",
        data() {
            return {
                locale: this.$i18n.locale
            }
        },
        methods: {
            setLocale(locale) {
                this.$i18n.locale = locale;
                this.$cookies.set('locale', locale);
                this.$store.dispatch('Locale/changeLocale', locale);
                this.$router.push(this.$cookies.get('locale') === 'en' ? '/' + this.$cookies.get('locale') + '/requisites' : '/requisites').catch(() => {
                });
            }
        },
        components: {},
        watch: {
            '$i18n.locale': function (newVal) {
                this.locale = newVal;
            }
        }
    }
</script>

<style scoped>

</style>

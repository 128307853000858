<template>
    <div class="content" id="contacts">
        <div class="q-title">{{ $t('menu.contacts') }}</div>

        <div class="contact-block">
            <a href="https://www.instagram.com/will.in.ua/"><i class="fa-brands fa-instagram"></i></a>
            <div>
                dopomoga.will@gmail.com
            </div>
        </div>
        <hr>
        <div class="contact-under">
            <div>{{ $t('text.help.to.save') }}</div>
            <div>{{ $t('text.ukraine') }} 🇺🇦 {{ $t('text.above.all') }}!</div>
        </div>
        <div class="head-btns">
            <router-link :to="this.$route.params.locale ? '/'+this.$route.params.locale+'/requisites' :'/requisites'"
                         class="btn11">
                {{ $t('text.donate') }}
            </router-link>
        </div>
    </div>

</template>

<script>

    export default {
        name: "ContactComponent",
        components: {},
    }
</script>

<style scoped>

</style>

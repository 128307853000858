<template>
    <div class="wide" id="team">
        <div class="content">
            <div class="team-title">{{ $t('menu.team') }}</div>
            <div class="team-block">
                <div class="team-item">
                    <img src="/images/12.png"/>
                    <div class="ti-name">{{ $t('dmytro.davydov') }}</div>
                    <div class="ti-stuff">{{ $t('text.director') }}</div>
                    <div class="ti-social">
                        <a href="https://www.facebook.com/davydovdmy"><img src="/images/icons/ico-fb.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/11.png"/>
                    <div class="ti-name">{{ $t('levandovskiyw') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.facebook.com/levandovskiyw/"><img src="/images/icons/ico-fb.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/13.png"/>
                    <div class="ti-name">{{ $t('vlada.yasko') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.facebook.com/vlada.yasko"><img src="/images/icons/ico-fb.png"/></a>
                        <a href="https://www.instagram.com/v__yasko/"><img src="/images/icons/ico -instagram.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/14.png"/>
                    <div class="ti-name">{{ $t('alisa.batiuk') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.facebook.com/AlisaBatiuk"><img src="/images/icons/ico-fb.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/15.png"/>
                    <div class="ti-name">{{ $t('kupedonio') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.instagram.com/kupedonio/"><img src="/images/icons/ico -instagram.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/16.png"/>
                    <div class="ti-name">{{ $t('videiko') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.facebook.com/profile.php?id=100007729994924"><img
                            src="/images/icons/ico-fb.png"/></a>
                        <a href="https://www.instagram.com/okiediv/"><img src="/images/icons/ico -instagram.png"/></a>
                    </div>
                </div>
                <div class="team-item">
                    <img src="/images/17.png"/>
                    <div class="ti-name">{{ $t('daryshyn') }}</div>
                    <div class="ti-stuff">{{ $t('text.volunteer') }}</div>
                    <div class="ti-social">
                        <a href="https://www.instagram.com/daryshyn/"><img src="/images/icons/ico -instagram.png"/></a>
                    </div>
                </div>
            </div>
            <SliderComponent/>
        </div>

    </div>

</template>

<script>

import SliderComponent from "./SliderComponent";

export default {
    name: "TeamComponent",
    components: {
        SliderComponent
    }
}
</script>

<style scoped>

</style>

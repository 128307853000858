<template>
    <div class="body">
        <div class="stripe"/>
        <HeaderComponent/>

        <slot></slot>

        <ContactComponent/>
        <FooterComponent/>
        <div v-show="scY > 300" @click="scrollToTop" class="to-top"><i class="fa-solid fa-arrow-up-long"></i></div>
    </div>

</template>

<script>
    import HeaderComponent from "@/components/HeaderComponent";
    import FooterComponent from "@/components/FooterComponent";
    import ContactComponent from "@/components/ContactComponent";

    export default {
        name: "AppLayout",
        components: {
            HeaderComponent,
            FooterComponent,
            ContactComponent
        },
        metaInfo() {
            return {
                title: this.meta.title,
                meta: [
                    {vmid: 'description', name: 'description', content: this.meta.description},
                    {vmid: 'keywords', name: 'keywords', content: this.meta.keywords},
                    {vmid: 'og:type', property: 'og:type', content: this.meta.type},
                    {vmid: 'og:site_name', property: 'og:site_name', content: this.meta.site_name},
                    {vmid: 'og:url', property: 'og:url', content: this.meta.url},
                    {vmid: 'og:locale', property: 'og:locale', content: this.meta.locale},
                    {vmid: 'og:title', property: 'og:title', content: this.meta.title},
                    {vmid: 'og:image', property: 'og:image', content: this.meta.image},
                    {vmid: 'og:description', property: 'og:description', content: this.meta.description},
                ],
            }
        },
        data() {
            return {
                meta: {
                    title: 'Charity Foundation "WILL IN UA"',
                    description: 'We are strengthening the effectiveness of our defenders',
                    keywords: 'ukraine army, uasf, azov, military support, charity ukraine',
                    site_name: document.location.hostname,
                    type: 'website',
                    locale: this.$i18n.locale,
                    image: document.location.origin + '/images/og-will.png',
                    url: document.location.href,
                },
                scTimer: 0,
                scY: 0
            };
        },
        methods: {
            handleScroll: function () {
                if (this.scTimer) return;
                this.scTimer = setTimeout(() => {
                    this.scY = window.scrollY;
                    clearTimeout(this.scTimer);
                    this.scTimer = 0;
                }, 100);
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },
            initMeta() {
                this.meta.image = document.location.origin + '/images/og-will.png';
                this.meta.locale = this.$i18n.locale;
                this.meta.site_name = document.location.hostname;
                this.meta.url = document.location.href;
                this.meta.title = 'Charity Foundation "WILL IN UA"';
                this.meta.description = 'We are strengthening the effectiveness of our defenders';
                this.meta.keywords = 'ukraine army, uasf, azov, military support, charity ukraine';
            }
        },
        mounted() {
            // this.initMeta();
            window.addEventListener("scroll", this.handleScroll);
        },
    }
</script>

<style scoped>

</style>

<template>
   <div id="reports" class="slider">
      <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
         <div class="slide" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`"><img
             :src="slide"/></div>
      </agile>
      <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
         <div class="slide slide--thumbniail" v-for="(slide, index) in slides" :key="index"
              :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="slide"/></div>
         <!--            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>-->
         <!--            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>-->
      </agile>
   </div>

</template>

<script>

import {VueAgile} from 'vue-agile';

export default {
   name: "SliderComponent",
   components: {
      agile: VueAgile,
   },
   data() {
      return {
         asNavFor1: [],
         asNavFor2: [],
         options1: {
            dots: false,
            fade: true,
            navButtons: false
         },
         options2: {
            autoplay: true,
            centerMode: true,
            dots: false,
            navButtons: false,
            slidesToShow: 3,
            responsive: [
               {
                  breakpoint: 600,
                  settings: {
                     slidesToShow: 5
                  }
               },
               {
                  breakpoint: 1000,
                  settings: {
                     navButtons: false
                  }
               }]
         },
         slides: [
            '/images/slider/219.jpg',
            '/images/slider/222.jpg',
            '/images/slider/220.jpg',
            '/images/slider/221.jpg',
            '/images/slider/201.jpg',
            '/images/slider/202.jpg',
            '/images/slider/203.jpg',
            '/images/slider/204.jpg',
            '/images/slider/205.jpg',
            '/images/slider/206.jpg',
            '/images/slider/207.jpg',
            '/images/slider/208.jpg',
            '/images/slider/209.jpg',
            '/images/slider/210.jpg',
            '/images/slider/211.jpg',
            '/images/slider/212.jpg',
            '/images/slider/213.jpg',
            '/images/slider/214.jpg',
            '/images/slider/215.jpg',
            '/images/slider/217.jpg',
            '/images/slider/218.jpg',
         ]
      };
   },
   mounted() {
      this.asNavFor1.push(this.$refs.thumbnails);
      this.asNavFor2.push(this.$refs.main);
   }
}
</script>

<style>

</style>

import Vue from 'vue';
import Vuex from 'vuex'
import Flags from "./modules/Flags";
import Locale from "./modules/Locale";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        Flags,
        Locale
    }
});
